import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import GardenContext from "./GardenContext";

const GridMenu = () => {
  const { dispatch } = useContext(GardenContext);
  const [rowsInput, setRowsInput] = useState(1);
  const [colsInput, setColsInput] = useState(1);

  const [gridSize, setGridSize] = useState({ rows: 1, cols: 1 });

  useEffect(() => {
    dispatch({
      type: "INIT_GRID",
      payload: { rows: gridSize.rows, cols: gridSize.cols },
    });
    return;
  }, [gridSize.rows, gridSize.cols]);

  const setNumber = (e, setInput) => {
    const value = parseInt(e.target.value, 10);
    setInput(value >= 1 ? Math.min(value, 10) : 1);
  };

  return (
    <Form className={"mb-2 "}>
      <Form.Group className="mb-2">
        <Form.Label>Rows</Form.Label>
        <Form.Control
          type="number"
          max="10"
          min="1"
          value={rowsInput}
          onChange={(e) => {
            setNumber(e, setRowsInput);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Cols</Form.Label>
        <Form.Control
          type="number"
          max="10"
          min="1"
          value={colsInput}
          onChange={(e) => {
            setNumber(e, setColsInput);
          }}
        />
      </Form.Group>
      <Button
        onClick={() => {
          setGridSize({ rows: rowsInput, cols: colsInput });
        }}
      >
        Build Grid
      </Button>
    </Form>
  );
};

export default GridMenu;
