import React from 'react';

function TermsOfService() {
  return (
    <div className="container mt-5">
      <h1 className="mb-3">Terms of Service</h1>
      <p>By using our website, you agree to comply with these terms and conditions. If you do not agree with any part of these terms, please do not use our website.</p>
      <p>You may not use our website for any illegal or unauthorized purpose. You are responsible for ensuring that your use of our website complies with applicable laws.</p>
      <p>We reserve the right to modify or terminate the service for any reason, without notice, at any time. We may also update these terms from time to time.</p>
      <p>Use of our website is at your own risk. We are not responsible for any loss of data or damages that result from your use of our website.</p>
    </div>
  );
}

export default TermsOfService;
