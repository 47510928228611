import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function Demos() {
  const demos = [
    {
      id: "shopify",
      title: "Shopify Shopfront",
      description: "A Shopify driven shopfront using React.",
    },
    {
      id: "garden",
      title: "Garden Planner",
      description: "Garden planning prototype.",
    },
    {
      id: "questionnaire",
      title: "Questionnaire",
      description:
        "Dynamic questionnaire defined in JSON and rendered with plain ol JS.",
    },
    {
      id: "spotify",
      title: "Spotify Demo",
      description:
        "Community comments tool for tracks. This is a prototype with no saving and example comments.",
    },
  ];

  return (
    <>
      <Container className="my-3 demos-container">
        <h1>Demos</h1>
        <Row>
          {demos.map((demo) => (
            <Col key={demo.id}>
              <Card>
                <Card.Header className="h2">{demo.title}</Card.Header>
                <Card.Body>
                  <Card.Text>{demo.description}</Card.Text>
                  <div className="view-demo">
                    <Link to={`/demo/${demo.id}`} className="btn btn-primary">
                      View This Demo
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
export default Demos;
