// Mock database for prototype
let commentsDB = [
  {
    id: 0,
    trackId: 0,
    time: 59,
    text: "Ahh this parrt!",
    author: "Demo User",
  },
  {
    id: 1,
    trackId: 0,
    time: 127,
    text: "The breakdown!",
    author: "User123",
  },
  {
    id: 2,
    trackId: 1,
    time: 5,
    text: "Love this playlist!",
    author: "User123",
  },
  {
    id: 3,
    trackId: 1,
    time: 67,
    text: "Great selection of songs.",
    author: "MusicFan",
  },
  // ... other comments
];

const CommentService = {
  getAllCommentsByTrackId: (trackId) => {
    return commentsDB.filter((comment) => {
      return comment.trackId === trackId;
    });
  },
  getAllComments: () => {
    return commentsDB;
  },
  addComment: (text, trackId, time, author) => {
    const newComment = { id: Date.now(), trackId, time, text, author };
    console.log(newComment);
    commentsDB.push(newComment);
    return newComment;
  },
  deleteComment: (commentId) => {
    commentsDB = commentsDB.filter((comment) => comment.id !== commentId);
    return commentsDB;
  },
  // More methods like updateComment, getCommentById, etc., could be added here
};

export default CommentService;
