import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { auth } from "./libs/firebase/Firebase";
import "./assets/styles/App.css";

import {
  Home,
  About,
  PrivacyPolicy,
  TermsOfService,
  Dashboard,
  Demos,
} from "./pages";
import {
  Header,
  Footer,
  ShopifyDemo,
  ProductDetail,
  QuestionnairDemo,
  SpotifyDemo,
  GardenDemo,
} from "./components";

function App() {
  //const user = auth.currentUser;
  const [user, setUser] = useState(null);
  const [redirected, setRedirected] = useState(true);

  const playlistId = "37i9dQZEVXcFaJM1MwPE52";

  //Handle When Authenticated
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setRedirected(false);
      if (user) {
        setUser(user);
        console.log("user set: " + user.email);
      } else {
        setUser(null);
        console.log("user removed");
      }
    });

    return () => unsubscribe();
  }, [user]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!redirected) {
      // If there is a user and the user has an email, redirect to Dashboard
      if (user && user.email) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
      setRedirected(true);
    }
  }, [user]); // Run the effect when the user state changes

  return (
    <div className="App bg-dark">
      <Header />
      <Routes basename="/myapp">
        <Route
          path="/"
          element={user && user.email ? <Dashboard /> : <Home />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/demos" element={<Demos />} />
        <Route path="/demo/shopify" element={<ShopifyDemo />} />
        <Route path="/demo/garden" element={<GardenDemo />} />
        <Route
          path="/demo/spotify"
          element={<SpotifyDemo playlistId={playlistId} />}
        />
        <Route path="/demo/questionnaire" element={<QuestionnairDemo />} />
        <Route
          path="/demo/shopify/product/:productId"
          element={<ProductDetail />}
        />
        {user && user.email && (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
          </>
        )}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
