import React from "react";
import GardenPlanner from "./GardenPlanner";
import { GardenProvider } from "./GardenContext";

const GardenDemo = () => {
  return (
    <>
      <GardenProvider>
        <GardenPlanner></GardenPlanner>
      </GardenProvider>
    </>
  );
};

export default GardenDemo;
