const db = [
  {
    name: "Tomato",
    src: "tomato.png",
    soilType: "Loamy",
    sunlight: "Full Sun",
    waterNeeds: "Regular watering",
    spacing: "24 inches apart",
    plantingDepth: "0.25 inches",
    maturity: "60-80 days",
    companionPlants: ["Basil", "Marigold"],
    avoid: ["Potato", "Corn"],
  },
  {
    name: "Carrot",
    src: "carrot.png",
    soilType: "Sandy",
    sunlight: "Full Sun to Partial Shade",
    waterNeeds: "Regular, even watering",
    spacing: "3 inches apart",
    plantingDepth: "0.5 inches",
    maturity: "50-75 days",
    companionPlants: ["Lettuce", "Rosemary"],
    avoid: ["Dill", "Parsnip", "Tomato"],
  },
  {
    name: "Lettuce",
    src: "lettuce.png",
    soilType: "Loamy",
    sunlight: "Partial Shade",
    waterNeeds: "Consistent moisture",
    spacing: "12 inches apart",
    plantingDepth: "0.25 inches",
    maturity: "45-55 days",
    companionPlants: ["Strawberries", "Cucumbers"],
    avoid: ["Parsley", "Celery"],
  },
  {
    name: "Cucumber",
    src: "cucumber.png",
    soilType: "Rich, well-drained",
    sunlight: "Full Sun",
    waterNeeds: "Regular watering",
    spacing: "36 to 60 inches apart",
    plantingDepth: "1 inch",
    maturity: "50-70 days",
    companionPlants: ["Beans", "Corn"],
    avoid: ["Potato", "Aromatic Herbs"],
  },
  // ... additional plants ...
  {
    name: "Path",
    src: "path.png",
    soilType: null,
    sunlight: null,
    waterNeeds: null,
    spacing: null,
    plantingDepth: null,
    maturity: null,
    companionPlants: [],
    avoid: [],
  },
];

export const getPlant = (name) => {
  return db.filter((p) => {
    return p.name === name;
  });
};

export const getAllPlants = () => {
  return [...db];
};
