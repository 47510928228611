import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBrXepRcZ0ipDh3WquJZxZRif_7sqNeyY8",
  authDomain: "skeleton-app-5d0fc.firebaseapp.com",
  projectId: "skeleton-app-5d0fc",
  storageBucket: "skeleton-app-5d0fc.appspot.com",
  messagingSenderId: "442176611267",
  appId: "1:442176611267:web:2ee02df4650f26d919d376",
  measurementId: "G-ZVST16TZB2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

//Firebase Utility Methods
const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "The email address is badly formatted.";
    case "auth/user-disabled":
      return "This user has been disabled. Please contact support.";
    case "auth/user-not-found":
    case "auth/wrong-password":
    case "auth/invalid-login-credentials":
      return "Invalid login credentials. Please try again.";
    // Add more cases for other error codes as needed
    default:
      return "An unexpected error occurred. Please try again later.";
  }
};

const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
    // User successfully signed in with Google
  } catch (error) {
    console.error(error.message);
  }
};
export { auth, analytics, storage, signInWithGoogle, getErrorMessage };
export default app; // Export the initialized Firebase app
