import { useState, useCallback } from "react";
import client from "../libs/shopifyClient";

export function useShopifyService() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllProducts = async () => {
    setLoading(true);
    try {
      const products = await client.product.fetchAll();
      setProducts(products);
      setError(null);
    } catch (err) {
      setError(err);
      console.error("Failed to fetch products:", err);
    } finally {
      setLoading(false);
    }
  };

  // Define other methods like fetchProductById, createCheckout, etc.
  const fetchProductById = useCallback(async (id) => {
    setLoading(true);
    try {
      const product = await client.product.fetch(id);
      setProduct(product);
      setError(null);
    } catch (err) {
      setError(err);
      console.error("Failed to fetch product:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  //createCheckout
  const createCheckout = () => {
    //TODO
  };

  return {
    products,
    product,
    loading,
    error,
    fetchAllProducts,
    fetchProductById,
    createCheckout,

    // Expose other methods...
  };
}
