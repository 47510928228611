import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { LoginModal } from "./auth";
import { auth } from "../libs/firebase/Firebase";
import logo from "../assets/images/logo with name.png";

function Header() {
  const [userDisplayName, setUserDisplayName] = useState(null);

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      setUserDisplayName(user.email);
    }
  }, [auth.currentUser]);

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Handle any signout-related actions you need.
      setUserDisplayName(null);
    });
  };

  const renderLoginorLogout = () => {
    if (userDisplayName) {
      return (
        <a className="dropdown-item" href="/" onClick={handleLogout}>
          Logout
        </a>
      );
    } else {
      return <LoginModal />;
    }
  };

  const renderPrivateLinks = () => {
    if (userDisplayName) {
      return (
        <>
          <li className="nav-item">
            <Link className="nav-link" to="/products">
              Products
            </Link>
          </li>
        </>
      );
    }
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            className="App-logo"
            alt="Obi Hardy Logo"
            height="30" // Set the height or width as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/demos">
              Demos
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            {renderPrivateLinks()}
          </Nav>
          <Nav>
            <NavDropdown
              title={
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>{" "}
                  {userDisplayName || ""}{" "}
                </span>
              }
              id="user-dropdown"
              align="end"
            >
              <NavDropdown.Item>{renderLoginorLogout()}</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
