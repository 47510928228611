import React from "react";
import ProductList from "./ProductsList";

const ShopifyDemo = () => {
  return (
    <>
      <ProductList />
    </>
  );
};

export default ShopifyDemo;
