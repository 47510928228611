import React, { useEffect } from "react";
import { useShopifyService } from "../../../services/ShopifyService";
import { Card, CardBody } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getImageSrcWithSize, currencySymbols } from "../../../utils/util";

function ProductDetails() {
  console.log(useParams());
  const { productId } = useParams();
  const cleanProductId = productId.replaceAll("-", "/");
  const { product, loading, error, fetchProductById } = useShopifyService();

  useEffect(() => {
    fetchProductById(cleanProductId);
  }, [cleanProductId, fetchProductById]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading product: {error.message}</div>;
  if (!product) return <div>Product not found</div>;

  return (
    <>
      <Card>
        <div>
          <Card.Header>{product.title}</Card.Header>
          <Card.Img
            variant="left"
            src={getImageSrcWithSize(product.images[0].src, 500, 500)}
            alt={product.alt}
          />
          <CardBody variant="right">
            <Card.Text
              className={
                " m-2 " +
                (!product.availableForSale && "text-decoration-line-through")
              }
            >
              {currencySymbols[product.variants[0].priceV2.currencyCode]}
              {product.variants[0].priceV2.amount}
            </Card.Text>
            <Card.Text>{product.description}</Card.Text>
          </CardBody>
        </div>
        <div></div>
      </Card>
    </>
  );
}

export default ProductDetails;
