import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import GardenContext from "./GardenContext";
import { getAllPlants } from "../../../services/GardenService";

const PlantSelection = () => {
  const { state, dispatch } = useContext(GardenContext);
  const [plants, setPlants] = useState(getAllPlants());
  const [sideOpen, setSideOpen] = useState(true);
  const handleSelectPlant = (plant) => {
    if (state.selectedPlant?.name !== plant?.name)
      dispatch({ type: "SELECT_PLANT", payload: plant });
  };

  return (
    <>
      <div className="plant-selection-container">
        <Container className="plant-selection">
          {plants.map((plant) => (
            <Row key={plant.name}>
              <Col
                className={
                  "plant-tile" +
                  (state.selectedPlant?.name === plant?.name ? " active" : "")
                }
                onClick={() => handleSelectPlant(plant)}
              >
                {plant.name}
              </Col>
            </Row>
          ))}
        </Container>
      </div>
      {() => {
        if (!sideOpen) {
          <div
            onClick={() => {
              setSideOpen(true);
            }}
            className="gp-open-arrow"
          >
            <i className="fa fa-arrow-right"></i>
          </div>;
        } else {
          <div
            onClick={() => {
              setSideOpen(false);
            }}
            className="gp-close-arrow"
          >
            <i className="fa fa-arrow-left"></i>
          </div>;
        }
      }}
    </>
  );
};

export default PlantSelection;
