import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-section">
      <div className="hero-text">
        <h1>Welcome to Obi Hardy's Portfolio</h1>
        <p>
          Discover and interact with the features and projects I've built! Get
          hands-on experience with my interactive demos and explore the
          possibilities.
        </p>
      </div>
      <Link to={"/demos"}>
        <button className="demo-button">View Demos</button>
      </Link>
    </div>
  );
}

export default HeroSection;
