import React, { useState, useEffect, useRef } from "react";
import CommentService from "../../../services/CommentService";
import { auth } from "../../../libs/firebase/Firebase";
import { Button, Form, ListGroup, FormGroup } from "react-bootstrap";

const TrackComments = ({ trackId, progress, seek }) => {
  const [loggedInWithSpotify, setLoggedInWithSpotify] = useState(true);
  const [comments, setComments] = useState([]);
  const commentText = useRef();
  const [author] = useState(auth.currentUser?.displayName ?? "Anon");

  useEffect(() => {
    // Load comments from the service when the component mounts
    const initialComments = CommentService.getAllCommentsByTrackId(trackId);
    setComments(initialComments);
  }, [trackId]);

  const handleAddComment = () => {
    const newComment = CommentService.addComment(
      commentText.current.value,
      trackId,
      progress,
      author
    );
    console.log(newComment);
    setComments(CommentService.getAllCommentsByTrackId(trackId));
  };

  const getTimeInMin = (seconds) => {
    return (
      Math.floor(seconds / 60) +
      ":" +
      (seconds % 60).toString().padStart(2, "0")
    );
  };

  const doSpotifyLogin = () => {};

  const renderComments = () => {
    if (loggedInWithSpotify) {
      return (
        <>
          <h2>
            Add Comment on Track {trackId + 1} and Time (
            {getTimeInMin(progress)})
          </h2>
          <Form className="mb-4">
            <FormGroup className="mb-2">
              <Form.Label>Comment:</Form.Label>
              <Form.Control as="textarea" rows={3} ref={commentText} />
            </FormGroup>

            <Button onClick={handleAddComment}> Add Comment </Button>
          </Form>
          <ListGroup className="text-start" variant="flush">
            {comments.map((comment, i) => (
              <ListGroup.Item
                key={comment.id}
                variant={i % 2 == 0 ? "light" : "dark"}
              >
                <strong>
                  {comment.author}{" "}
                  <a
                    onClick={() => {
                      seek(comment.time);
                    }}
                  >
                    @{getTimeInMin(comment.time)}
                  </a>
                </strong>
                : {comment.text}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      );
    } else {
      return (
        <a href="">
          <button onClick={doSpotifyLogin}>Connect to Spotify</button>
        </a>
      );
    }
  };

  return <div>{renderComments()}</div>;
};

export default TrackComments;
