import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import GardenSquare from "./GardenSquare";
import { GardenContext } from "./GardenContext.js";

const GardenGrid = () => {
  const { state, dispatch } = useContext(GardenContext);

  const renderGrid = () => {
    let grid = [];
    for (let r = 0; r < state.gardenGrid.length; r++) {
      let columns = [];
      for (let c = 0; c < state.gardenGrid[r].length; c++) {
        columns.push(
          <Col
            className="garden-col"
            key={`col-${r}-${c}`}
            style={{ padding: 0 }}
          >
            <GardenSquare row={r} col={c} plant={state.gardenGrid[r][c]} />
          </Col>
        );
      }
      grid.push(
        <Row className="garden-row" key={`row-${r}`} style={{ padding: 0 }}>
          {columns}
        </Row>
      );
    }

    return grid;
  };

  return (
    <>
      <Container className="garden-grid-container">
        <div>{renderGrid()}</div>
      </Container>
    </>
  );
};

export default GardenGrid;
