import { hover } from "@testing-library/user-event/dist/hover";
import React, { createContext, useReducer } from "react";

export const GardenContext = createContext();

const initialState = {
  selectedPlant: null,
  hoverPlant: null,
  rows: 1,
  cols: 1,
  gardenGrid: createEmptyGrid(1, 1),
};

// Function to create an empty grid
function createEmptyGrid(r, c) {
  return Array.from({ length: r }, () => Array(c).fill(null));
}

function validateGrid(grid) {
  for (let r = 0; r < grid.length; r++) {
    for (let c = 0; c < grid[r].length; c++) {
      validatePlantPlacement(grid, r, c);
    }
  }
}

function setStatus(plant, status) {
  if (!plant["status"]) plant["status"] = status;

  if (plant["status"] === "bad" && status === "good") plant["status"] = "mixed";
  if (plant["status"] === "good" && status === "bad") plant["status"] = "mixed";
}

function validatePlantPlacement(grid, r, c) {
  let plant = grid[r][c];
  //check adjacentplants
  if (!plant) return;

  plant["status"] = null;
  if (r > 0 && c > 0) {
    if (checkAvoid(grid, r - 1, c - 1, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r - 1, c - 1, plant))
      setStatus(plant, "good");
  }
  if (r > 0) {
    if (checkAvoid(grid, r - 1, c, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r - 1, c, plant)) setStatus(plant, "good");
  }
  if (c > 0) {
    if (checkAvoid(grid, r, c - 1, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r, c - 1, plant)) setStatus(plant, "good");
  }
  if (r < grid.length - 1 && c < grid[r].length - 1) {
    if (checkAvoid(grid, r + 1, c + 1, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r + 1, c + 1, plant))
      setStatus(plant, "good");
  }
  if (r < grid.length - 1) {
    if (checkAvoid(grid, r + 1, c, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r + 1, c, plant)) setStatus(plant, "good");
  }
  if (c < grid[r].length - 1) {
    if (checkAvoid(grid, r, c + 1, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r, c + 1, plant)) setStatus(plant, "good");
  }

  // Check diagonal squares
  if (r > 0 && c < grid[r].length - 1) {
    if (checkAvoid(grid, r - 1, c + 1, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r - 1, c + 1, plant))
      setStatus(plant, "good");
  }
  if (r < grid.length - 1 && c > 0) {
    if (checkAvoid(grid, r + 1, c - 1, plant)) setStatus(plant, "bad");
    else if (checkCompanion(grid, r + 1, c - 1, plant))
      setStatus(plant, "good");
  }

  return plant;
}

function checkAvoid(grid, r, c, plant) {
  if (!grid[r][c]) return false;

  let avoid = plant.avoid.filter((a) => {
    return grid[r][c].name === a;
  });
  return avoid.length > 0;
}

function checkCompanion(grid, r, c, plant) {
  if (!grid[r][c]) return false;
  let fwend = plant.companionPlants.filter((friend) => {
    return grid[r][c].name === friend;
  });
  return fwend.length > 0;
}

function reducer(state, action) {
  let newGrid;
  let r = action.payload?.row;
  let c = action.payload?.col;
  const updatedGrid = [...state.gardenGrid];
  let selectedPlant = state.selectedPlant;
  switch (action.type) {
    case "INIT_GRID":
      return {
        ...state,
        gardenGrid: createEmptyGrid(action.payload.rows, action.payload.cols),
      };
    case "SELECT_PLANT":
      return { ...state, selectedPlant: action.payload };
    case "HOVER_PLANT":
      if (action.payload?.name === "Path") return { ...state };
      return { ...state, hoverPlant: action.payload };

    case "REMOVE_HOVER_PLANT":
      return { ...state, hoverPlant: null };
    case "UPDATE_ROW":
      return {
        ...state,
        gardenGrid: createEmptyGrid(action.payload, state.cols),
        rows: action.payload,
      };
    case "UPDATE_COL":
      return {
        ...state,
        gardenGrid: createEmptyGrid(state.rows, action.payload),
        rows: action.payload,
      };
    case "UPDATE_GRID":
      return {
        ...state,
        gardenGrid: createEmptyGrid(action.payload.rows, action.payload.cols),
      };
    case "UPDATE_CELL":
      updatedGrid[r][c] = { ...selectedPlant };
      validateGrid(updatedGrid);
      return { ...state, gardenGrid: updatedGrid };
    case "CLEAR_CELL":
      updatedGrid[action.payload.row][action.payload.col] = null;
      validateGrid(updatedGrid);
      return { ...state, gardenGrid: updatedGrid };
    default:
      return state;
  }
}

export const GardenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GardenContext.Provider value={{ state, dispatch }}>
      {children}
    </GardenContext.Provider>
  );
};

export default GardenContext;
