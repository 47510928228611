import React from "react";

function About() {
  return (
    <div className="container">
      <h1>About Me</h1>
      <p>
        Hi, I'm Obi Hardy, an adept full-stack developer transitioning my almost
        a decade of experience with Java and C# to modern web applications in
        React.js.
      </p>
      <p>
        My portfolio, built entirely with React, demonstrates a firm commitment
        to crafting intuitive, dynamic user experiences.
      </p>
      <p>
        I excel in merging technical skills with client communication to deliver
        projects that align with both tech and business needs. Let's create
        something innovative together.
      </p>
    </div>
  );
}

export default About;
