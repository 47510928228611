import React, { useEffect, useState } from "react";
import { auth } from "../../libs/firebase/Firebase"; // Import Firebase auth

function Dashboard() {
  const [userDisplayName, setUserDisplayName] = useState(null);

  useEffect(() => {
    // Fetch the user's display name from Firebase (assuming it's available)
    const user = auth.currentUser;

    if (user) {
      setUserDisplayName(user.displayName);
    }
  }, []);

  return (
    <div>
      <header>
        <h1>Welcome, {userDisplayName || "User"}</h1>
      </header>
      {/* The rest of your dashboard content */}
    </div>
  );
}

export default Dashboard;
