export const getImageSrcWithSize = (src, width, height) => {
  const sizeFragment = `_${width}x${height}`;
  // Insert the size fragment before the file extension
  return src.replace(/(\.\w{3,4})(\?.*)?$/, `${sizeFragment}$1`);
};

export const currencySymbols = {
  USD: "$", // United States Dollar
  EUR: "€", // Euro
  JPY: "¥", // Japanese Yen
  GBP: "£", // British Pound Sterling
  AUD: "A$", // Australian Dollar
  CAD: "C$", // Canadian Dollar
  CNY: "¥", // Chinese Yuan
};
