import React, { useState } from "react";
import GardenGrid from "./GardenGrid";
import PlantSelection from "./PlantSelection.js";
import { Container, Row, Col } from "react-bootstrap";
import GridMenu from "./GridMenu.js";
import PlantDetail from "./PlantDetail.js";

const GardenPlanner = () => {
  return (
    <div className="garden-planner">
      <Container>
        <Row>
          <Col>
            <GridMenu />
          </Col>
        </Row>
        <Row>
          <Col className="gp-menu">
            <PlantSelection />
          </Col>
          <Col>
            <GardenGrid />
          </Col>
          <Col>
            <PlantDetail />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GardenPlanner;
