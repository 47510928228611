import React, { useContext, useState, useEffect } from "react";

import { GardenContext } from "./GardenContext.js";
const GardenSquare = ({ row, col, plant }) => {
  const { state, dispatch } = useContext(GardenContext);
  const [plantImage, setPlantImage] = useState();

  // Function to handle plant drop
  function handleClick(row, col) {
    if (state.selectedPlant?.name != plant?.name) {
      dispatch({
        type: "UPDATE_CELL",
        payload: { row, col },
      });
    } else {
      dispatch({
        type: "CLEAR_CELL",
        payload: { row, col },
      });
    }
  }
  function handleMouseEnter(e) {
    dispatch({
      type: "HOVER_PLANT",
      payload: plant,
    });
  }
  function handleMouseExit(e) {
    dispatch({
      type: "REMOVE_HOVER_PLANT",
    });
  }

  useEffect(() => {
    if (plant != null) {
      import(`../../../assets/images/plants/${plant.src}`).then(
        (imageModule) => {
          setPlantImage(imageModule.default);
        }
      );
    }
  }, [plant]);
  const style = () => {
    if (!plant) return {};
    let borderColor = null;
    if (plant.status === "bad") borderColor = "red";
    else if (plant.status === "good") borderColor = "green";
    else if (plant.status === "mixed") borderColor = "orange";
    return {
      backgroundImage: `url(${plantImage})`,
      backgroundRepeat: "no-repeat",
      borderColor: borderColor,
    };
  };
  return (
    <>
      <div
        onClick={(e) => {
          handleClick(row, col);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseExit}
        className="garden-cell"
        style={style()}
      ></div>
    </>
  );
};

export default GardenSquare;
