import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardImg, Container } from "react-bootstrap";
import { useShopifyService } from "../../../services/ShopifyService";
import { getImageSrcWithSize, currencySymbols } from "../../../utils/util";

function ProductsList() {
  const { products, loading, error, fetchAllProducts } = useShopifyService();
  const columnSizes = [3, 4, 5, 6, 7, 8, 9, 10];
  const [colSizeIndex, setColSizeIndex] = useState(
    window.innerWidth > 1000 ? 4 : 0
  );
  const [itemsPerRow, setItemsPerRow] = useState(0);
  const gapSize = 10; // Gap size in pixels

  const containerStyle = {
    background:
      "linear-gradient(to right, #232323 0%, #ffffff 1%, #ffffff 99%, #232323 100%)",
    // Additional styles
    align: "center",
    justifyContent: "center",
    padding: " 5px 15px ",
  };

  //Update Columns to default when window is resized
  useEffect(() => {
    const handleResize = () => {
      // Update the index if the window width crosses the 1000px threshold
      setColSizeIndex(window.innerWidth > 1000 ? 4 : 0);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (products.length === 0) {
      fetchAllProducts();
    }
  }, [fetchAllProducts, products]);

  useEffect(() => {
    setItemsPerRow(columnSizes[colSizeIndex]);
  }, [colSizeIndex]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const increaseColumnSize = () => {
    setColSizeIndex((prevIndex) =>
      prevIndex < columnSizes.length - 1 ? prevIndex + 1 : prevIndex
    );
  };
  const decreaseColumnSize = () => {
    setColSizeIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const flexItemStyle = {
    flexGrow: 1,
    maxWidth: `calc(${100 / itemsPerRow}% - ${
      (gapSize * (itemsPerRow - 1)) / itemsPerRow
    }px)`,
    flex: `0 0 calc(${100 / itemsPerRow}% - ${
      (gapSize * (itemsPerRow - 1)) / itemsPerRow
    }px)`,
    boxSizing: "border-box",
    // Make sure to account for any padding or borders here as well
    padding: "0px",
  };
  const imageStyle = {
    width: "", // Make the image responsive
    height: "150px", // Maintain the aspect ratio
    objectFit: "cover", // This will cover the area without stretching the image
  };

  return (
    <>
      <h2>Products</h2>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <span className="mx-1" onClick={decreaseColumnSize}>
          <i className="bi bi-grid-fill icon-large"></i>
        </span>
        <span className="mx-1" onClick={increaseColumnSize}>
          <i className="bi bi-grid-3x3-gap-fill icon-large"></i>
        </span>
      </div>
      <Container style={containerStyle}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: `${gapSize}px`,
          }}
        >
          {products.map((product) => (
            <div key={product.id} style={flexItemStyle}>
              <Link
                className="text-decoration-none"
                to={`/demo/shopify/product/${product.id.replaceAll("/", "-")}`}
              >
                <Card
                  className={
                    "card-pop card-product " +
                    (!product.availableForSale && " soldout ")
                  }
                >
                  {/* Check if images are available before trying to access the src */}
                  <Card.Text
                    className={
                      " m-2 " +
                      (!product.availableForSale &&
                        "text-decoration-line-through")
                    }
                  >
                    {currencySymbols[product.variants[0].priceV2.currencyCode]}
                    {product.variants[0].priceV2.amount}
                  </Card.Text>
                  {product.images.length > 0 && (
                    <CardImg
                      variant="top"
                      src={getImageSrcWithSize(product.images[0].src, 400, 400)}
                      alt={product.images[0].altText}
                      style={imageStyle}
                    />
                  )}
                  <CardBody>
                    <Card.Title>{product.title}</Card.Title>
                    {product.description && (
                      <Card.Text className="description">
                        {product.description}
                      </Card.Text>
                    )}
                  </CardBody>
                </Card>
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}

export default ProductsList;
