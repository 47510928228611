import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TrackComments from "./TrackComments";

const SpotifyDemo = ({ playlistId }) => {
  const [init, setInit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [trackId, setTrackId] = useState(0);

  const playingRef = useRef(playing);
  const trackIdRef = useRef(trackId);

  const setProgressFromComment = (progress) => {
    window.EmbedController.seek(progress);
  };

  useEffect(() => {
    playingRef.current = playing;
  }, [playing]);

  useEffect(() => {
    trackIdRef.current = trackId;
  }, [trackId]);

  //Init Spotify Embed
  useEffect(() => {
    // Function to initialize the Spotify Embed
    const initializeSpotifyEmbed = (IFrameAPI) => {
      window.SpotifyIFrameAPI = IFrameAPI;
      const element = document.getElementById("embed-spotify-iframe");
      if (element != null && element.childNodes.length == 0) {
        const options = {
          width: "100%",
          height: 352,
          uri: `spotify:playlist:${playlistId}`,
          theme: 0,
          allow:
            "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
        };
        const callback = (EmbedController) => {
          window.EmbedController = EmbedController;
          EmbedController.addListener("playback_update", (e) => {
            let progress = parseInt(e.data.position / 1000, 10);
            if (progress > 0) {
              setPlaying(true);
            }
            setProgress(progress);
            if (playingRef.current && e.data.position == 0) {
              setTrackId(trackIdRef.current + 1);
              setPlaying(false);
            }
          });
        };
        window.SpotifyIFrameAPI.createController(element, options, callback);
      }
    };

    // have we done the embed init yet
    if (!init) {
      if (window.onSpotifyIframeApiReady) {
        if (window.SpotifyIFrameAPI) {
          initializeSpotifyEmbed(window.SpotifyIFrameAPI);
        }
      } else {
        const scriptExists = document.querySelector(
          'script[src="https://open.spotify.com/embed/iframe-api/v1"]'
        );
        if (!scriptExists) {
          // If not, inject the script
          const script = document.createElement("script");
          script.src = "https://open.spotify.com/embed/iframe-api/v1";
          script.async = true;
          script.onload = () => {
            window.onSpotifyIframeApiReady = initializeSpotifyEmbed;
          };
          document.body.appendChild(script);
        }
      }
      setInit(true);
    }

    return () => {};
  }, []);

  return (
    <>
      <Container type="fluid">
        <Row>
          <Col>
            <div id="embed-spotify-iframe"></div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TrackComments
              trackId={trackId}
              progress={progress}
              seek={setProgressFromComment}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SpotifyDemo;
