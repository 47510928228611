class QuestionnaireWidget {
  constructor(containerId, config) {
    this.container = document.querySelector(containerId);
    this.config = config;
    this.currentQuestionIndex = 0;
    this.userAnswers = [];
    this.init();
  }

  init = () => {
    this.renderCurrentQuestion();
    this.prevQuestion = this.prevQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.reset = this.reset.bind(this);
    this.seeJson = this.seeJson.bind(this);
    // Initialize event listeners for navigation buttons here...
  };

  reset() {
    this.currentQuestionIndex = 0;
    this.userAnswers = [];
    this.renderCurrentQuestion();
  }

  seeJson() {
    console.log(this.config);
  }

  prevQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.renderCurrentQuestion();
    }
  }

  nextQuestion() {
    this.saveCurrentAnswer();
    if (this.currentQuestionIndex < this.config.questions.length - 1) {
      this.currentQuestionIndex++;
      this.renderCurrentQuestion();
    } else {
      this.showResults();
    }
  }

  renderCurrentQuestion = () => {
    const question = this.config.questions[this.currentQuestionIndex];
    this.container.innerHTML = `
      <div class="card">
        <div class="card-title">
          <h2>${question.text}</h2>
        </div>
        <div class="card-body question">
          ${this.generateOptionsHtml(question)}
        </div>
          <div class="card-footer">
            <button class="btn btn-primary" id="prevQuestionButton">Previous</button>
            <button class="btn btn-primary" id="nextQuestionButton">Next</button>
          </div>
        </div>`;

    // Add more rendering logic for different types of questions...

    // Now, add event listeners to the buttons
    const prevButton = this.container.querySelector("#prevQuestionButton");
    const nextButton = this.container.querySelector("#nextQuestionButton");

    // Make sure to first remove the event listeners to avoid duplicate handlers if the method is called multiple times
    prevButton.removeEventListener("click", this.prevQuestion);
    nextButton.removeEventListener("click", this.nextQuestion);

    // Then, attach the event listeners
    prevButton.addEventListener("click", this.prevQuestion);
    nextButton.addEventListener("click", this.nextQuestion);
  };

  generateOptionsHtml = (question) => {
    return question.options
      .map(
        (option) =>
          `<div class="form-group input-group">
          <div class="form-check" style="width: 100%;">
            <input class="form-check-input" id="question-${
              option.value
            }" type="${
            question.type === "single-choice" ? "radio" : "checkbox"
          }" name="question${question.id}" data-weight="${
            option.weight
          }" value="${option.value}"/>
            <label style="width:100%; text-align:left" for="question-${
              option.value
            }">
              ${option.text}
            </label>
            </div>
          </div>`
      )
      .join("");
  };

  saveCurrentAnswer = () => {
    // Logic to save the answer...
    const answers = document.querySelectorAll(
      `input[name="question${
        this.config.questions[this.currentQuestionIndex].id
      }"]:checked`
    );
    this.userAnswers[this.currentQuestionIndex] = Array.from(answers).map(
      (answer) => answer.dataset.weight
    );
  };

  showResults = () => {
    // Logic to compute the conclusion based on userAnswers...
    const conclusion = this.computeConclusion();
    this.container.innerHTML = `
      <div class="card">
        <div class="card-title">
          <h1>Thank You!</h1>
        </div>    
        <div class="card-body conclusion">
          <p>${conclusion}</p>
        </div>
        <div class="card-footer">
          <span style="position: absolute; display: block;" id="seeJsonButton">See Json in console</span>
          <button class="btn btn-primary" id="resetButton">Reset</button>
        </div>
      </div>
      `;
    const resetButton = this.container.querySelector("#resetButton");
    const seeJsonButton = this.container.querySelector("#seeJsonButton");

    // Make sure to first remove the event listeners to avoid duplicate handlers if the method is called multiple times
    resetButton.removeEventListener("click", this.reset);
    seeJsonButton.removeEventListener("click", this.seeJson);

    // Then, attach the event listeners
    resetButton.addEventListener("click", this.reset);
    seeJsonButton.addEventListener("click", this.seeJson);
  };

  computeConclusion = () => {
    let answerWeight = 0;
    this.userAnswers.forEach((answer) => {
      answerWeight += parseInt(answer);
    });
    answerWeight /= this.userAnswers.length;
    let i = 0;
    Object.keys(this.config.logic).some((key) => {
      let threshold = this.config.logic[key];
      if (answerWeight >= threshold && i < this.config.conclusions.length - 1) {
        return i;
      }
      i++;
      return null;
    });

    if (i < this.config.conclusions.length - 1)
      return this.config.conclusions[i].text;
    //return last conclusion by default
    return this.config.conclusions[this.config.conclusions.length - 1].text;
  };
}
export default QuestionnaireWidget;
