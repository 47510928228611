export const exampleQW = {
  title: "Health Assessment",
  questions: [
    {
      id: 1,
      text: "How often do you exercise?",
      type: "single-choice",
      options: [
        { value: "daily", text: "Every Day", weight: 4 },
        { value: "often", text: "2-3 times a week", weight: 3 },
        { value: "rarely", text: "Rarely", weight: 2 },
        { value: "never", text: "Never", weight: 1 },
      ],
    },
    {
      id: 2,
      text: "How often do you eat out?",
      type: "single-choice",
      options: [
        { value: "daily", text: "Every Day", weight: 1 },
        { value: "often", text: "2-3 times a week", weight: 2 },
        { value: "rarely", text: "Rarely", weight: 3 },
        { value: "never", text: "Never", weight: 4 },
      ],
    },
    {
      id: 3,
      text: "How often do you eat salads and (or) lean protean?",
      type: "single-choice",
      options: [
        { value: "daily", text: "Every Day", weight: 4 },
        { value: "often", text: "2-3 times a week", weight: 3 },
        { value: "rarely", text: "Rarely", weight: 2 },
        { value: "never", text: "Never", weight: 1 },
      ],
    },
  ],
  conclusions: [
    {
      id: "active",
      text: "Based on your answers, you have an active lifestyle!",
    },
    {
      id: "healthy",
      text: "Based on your answers, you have an healthy lifestyle!",
    },
    {
      id: "unhealthy",
      text: 'Based on your answers, you have an unhealthy lifestyle! <a href="https://letmegooglethat.com/?q=get+healthy+now">:(</a>',
    },
  ],
  logic: {
    active: 3.5,
    healthy: 2.5,
    unhealthy: 1,
  },
};
