import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { auth } from "../../libs/firebase/Firebase";

function Register({ onModalHide, onRegisterComplete }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleRegister = async () => {
    if (password !== confirmPassword) return;
    try {
      await auth.createUserWithEmailAndPassword(email, password);
      // User is registered
      onRegisterComplete();
    } catch (error) {
      console.error("Error registering:", error);
    }
  };

  useEffect(() => {
    // Reset the form fields when the modal is about to be hidden
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setError("");
  }, [onModalHide]);

  return (
    <Form>
      <div className="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          className="form-control"
          id="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label for="confirmPassword">Confirm Password</label>
        <input
          type="password"
          className="form-control"
          id="confirmPassword"
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      {error && <p>error</p>}
      <button
        type="submit"
        className="btn btn-primary btn-block"
        onClick={handleRegister}
      >
        Register
      </button>
    </Form>
  );
}
export default Register;
