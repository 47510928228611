import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container mt-5">
      <h1 className="mb-3">Privacy Policy</h1>
      <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and disclose information from users of our website.</p>
      <p>We may collect information such as your name, email address, and browsing history. This information is used to improve your experience and for communication purposes.</p>
      <p>Your data is kept confidential and will not be shared with third parties unless required by law.</p>
      <p>By using our website, you agree to this Privacy Policy. If you have questions or concerns, please contact us.</p>
    </div>
  );
}

export default PrivacyPolicy;
