import React from "react";
import { HeroSection } from "../components";

function Home() {
  return (
    <div>
      <HeroSection />
    </div>
  );
}

export default Home;
