import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-dark text-light py-3">
      <div className="container">
        <p>&copy; {new Date().getFullYear()} Obi Hardy</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to="/privacy-policy" className="text-light">
              Privacy Policy
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms-of-service" className="text-light">
              Terms of Service
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
