import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import {
  signInWithGoogle,
  getErrorMessage,
} from "../../libs/firebase/Firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function Login({ onModalHide }) {
  // Define state variables to store user input
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [authError, setAuthError] = useState("");

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === true) {
      // Perform the login operation
      try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            console.log("Authenticated user:", user);
          })
          .catch((error) => {
            console.error("Authentication failed:", error.code);
            setAuthError(getErrorMessage(error.code));
          });
        // User successfully logged in
      } catch (error) {
        console.error(error.message);
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    // Reset the form fields when the modal is about to be hidden
    setEmail("");
    setPassword("");
    setValidated(false);
    setAuthError("");
  }, [onModalHide]);

  return (
    <Form noValidate validated={validated} onSubmit={handleEmailLogin}>
      <Form.Group className="mb-3" controlId="loginFormEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="loginFormPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Form.Control.Feedback type="invalid">
          Please provide a password.
        </Form.Control.Feedback>
      </Form.Group>
      {authError && <Alert variant="danger">{authError}</Alert>}

      <Button variant="primary" type="submit">
        Login with Email/Password
      </Button>

      <Button variant="primary" onClick={signInWithGoogle}>
        Login with Google
      </Button>
    </Form>
  );
}

export default Login;
