import React, { useEffect } from "react";

import QuestionnaireWidget from "../../assets/js/QuestionnaireWidget";
import { Container, Row, Col } from "react-bootstrap";
import { exampleQW } from "../../assets/js/exampleQW";

const QuestionnairDemo = () => {
  useEffect(() => {
    if (QuestionnaireWidget) {
      const myInstance = new QuestionnaireWidget("#questionnaire", exampleQW);
      myInstance.init();
    } else {
      console.log("QuestionnaireWidget is not defined");
    }
  }, []);

  return (
    <>
      <Container type="fluid">
        <Row>
          <Col>
            <div
              style={{ width: "50vw", margin: "auto" }}
              id="questionnaire"
            ></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default QuestionnairDemo;
