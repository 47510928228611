import React, { useContext } from "react";
import GardenContext from "./GardenContext";

const PlantDetail = () => {
  const { state, dispatch } = useContext(GardenContext);

  let plant = state.hoverPlant ? state.hoverPlant : state.selectedPlant;

  function renderPlant() {
    if (plant && plant.name !== "Path") {
      return (
        <>
          <div className="plant-detail">
            <h3>{plant.name}</h3>
            <p>
              <b>Soil Preference:</b> {plant.soilType}
            </p>
            <p>
              <b>Reaches Maturity:</b> {plant.maturity}
            </p>
            <p>
              <b>Planting Depth:</b> {plant.plantingDepth}
            </p>
            <p>
              <b>Spacing:</b> {plant.spacing}
            </p>
            <p>
              <b>Sunlight:</b> {plant.sunlight}
            </p>
            <p>
              <b>Water Needs:</b> {plant.waterNeeds}
            </p>
            <h5>Companions</h5>
            <ul>
              {plant.companionPlants.map((avoid) => (
                <li key={avoid}>{avoid}</li>
              ))}
            </ul>
            <h5>Avoid</h5>
            <ul>
              {plant.avoid.map((avoid) => (
                <li key={avoid}>{avoid}</li>
              ))}
            </ul>
          </div>
        </>
      );
    }
  }

  return renderPlant();
};
export default PlantDetail;
