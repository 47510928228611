import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Register, Login } from "./";

function LoginModal() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleForm = () => {
    setIsRegistering(!isRegistering);
  };

  useEffect(() => {
    setIsRegistering(false);
  }, [show]);

  return (
    <>
      <span className="dropdown-item" onClick={handleShow}>
        Login / Register
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isRegistering ? "Register" : "Login"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isRegistering ? (
            <Register onModalHide={show} />
          ) : (
            <Login onModalHide={show} />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => toggleForm()}>
            {isRegistering ? "Login" : "Register"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;
